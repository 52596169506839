import { React } from "react";
import { Component } from "react";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";
import {
  getClientForm,
  saveClientResponse,
} from "./../../../config/forms_api_calls";
import $ from "jquery";

import DataTableExtended from "../../../components/Tables/DataTableExtended";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import Typography from "@material-ui/core/Typography";
import FormRendererClient from "../../../components/Client/formRenderClient";
import { TableLoader } from "../../../components/ContentLoaders/newLoaders/TableLoader";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import dateFormat from "dateformat";

const auth = new HelperClass();

class FormSubmit extends Component {
  constructor() {
    super();

    this.state = {
      tableData: [],
      tableDataSingle: [],
      serviceData: [],
      tableLoaderListing: true,
      tableLoader: true,
      errorMessage: "",
      successMessage: "",
      showModal: false,
      modalContent: null,
      formAccNo: "",
      successMessageSaveResponse: "",
      errorMessageSaveResponse: "",
      form_accountno: "",
      form_token: "",
      formData: "",
      form_title: "",
      form_desc: "",
      client_details: [],
      form_submitted: "3",
      allow_multiple: 0,
      responseMessage: "",
      allow_submit: 1
    };
  }

  async componentDidMount() {
    let form_accountno = this.props.props.match.params.form_accountno;
    let form_token = this.props.props.match.params.form_token;
    this.setState({
      formAccNo: form_accountno,
      form_token: form_token,
    });
    const servicesResponce = await getClientForm(
      auth.getClientAccount(),
      auth.getClientToken(),
      form_accountno,
      form_token
    );
    console.log("getClientForm: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        tableLoader: false,
        tableLoaderListing: false,
        form_submitted: "3",
      });
    } else if (servicesResponce.data.status === 200) {
      const groupsData = servicesResponce.data.data[0]["groups"];
      const groups = groupsData ? JSON.parse(groupsData) : [];
      this.setState({
        formData: servicesResponce.data.data[0]["form_content"],
        form_title: servicesResponce.data.data[0]["form_title"],
        form_desc: servicesResponce.data.data[0]["form_desc"],
        allow_submit: servicesResponce.data.data[0]["allow_submit"],
        groups: groups,
        client_details: servicesResponce.data.client_details,
        form_submitted: servicesResponce.data.client_data[0]["is_filled"],
        allow_multiple: servicesResponce.data.client_data[0]["allow_multiple"],
        tableLoader: false,
        tableLoaderListing: false,
      });
    } else {
      this.setState({
        tableLoader: false,
        tableLoaderListing: false,
        form_submitted: "3",
      });
      //window.location.replace("/error");
    }
  }

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy, h:MM:ss TT");
  };

  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  openModal = (formData, formAccNo) => {
    const modalContent = (
      <FormRendererClient
        formData={JSON.parse(formData)}
        formTitle={this.state.form_title}
        onSubmit={this.handleSubmitForm}
        // id={this.state.forms[0].id}
        // setShowResponse={(value) => this.setState({ showResponse: value })}
      />
    );
    this.setState({ modalContent: modalContent, formAccNo: formAccNo }, () => {
      window.$("#modalPreviewForm").modal("show");
    });
  };

  closeModal = () => {
    this.setState({ modalContent: null, formAccNo: "" }, () => {
      window.$("#modalPreviewForm").modal("hide");
    });
  };

  handleSubmitForm = (formData) => {
    let response = JSON.stringify(formData);
    this.saveResponse(response, this.state.formAccNo);
  };

  saveResponse = async (response, formAccNo) => {
    const { form_token, form_title, form_desc } = this.state;
    window.scrollTo({ top: 0, behavior: "smooth" });
    const submitFormReponse = await saveClientResponse(
      auth.getClientAccount(),
      auth.getClientToken(),
      response,
      formAccNo,
      form_token,
      form_title,
      form_desc
    );
    console.log("saveResponse response: ", submitFormReponse.data);

    if (
      submitFormReponse.data.status === 403 ||
      submitFormReponse.data.errors === "authentication missing" ||
      submitFormReponse.data.errors === "jwt expired"
    ) {
      auth.clientLogout();
    } else if (submitFormReponse.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        responseMessage:
          '<div class="alert alert-danger alert-icon mb-3"><em class="icon ni ni-cross-circle"></em> There is some error while submitting the form.</div>',
      });
    } else if (submitFormReponse.data.status === 200) {
      this.setState({
        errorMessageSaveResponse: "",
        responseMessage: '<div class="alert alert-success alert-icon mb-3"><em class="icon ni ni-check-circle"></em> Your response submitted succesfully!</div>',
      });
      setTimeout(() => {
        this.props.props.history.push("/" + auth.getResellerRegToken() + "/client/forms");
        //window.location.reload();
      }, 3500);
    }
    setTimeout(() => {
      this.setState({
        responseMessage: '<div class="alert alert-danger alert-icon mb-3"><em class="icon ni ni-cross-circle"></em> There is some error while submitting the form.</div>',
        successMessageSaveResponse: "",
      });
    }, 4000);
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  render() {
    const { step } = this.state;
    const { email, VerifyUsernameButton } = this.state;
    const values = {
      email,
      VerifyUsernameButton,
    };
    //$(".nk-block .MUIDataTable-paper-2").css("padding", "10px");
    return (
      <div className="nk-content " id="Support_Block">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              {/* RIGHTS MODAL START */}

              {/* RIGHTS MODAL End */}
              <div className="nk-block-head nk-block-head-sm">
                <div className="nk-block-between">
                  <div className="nk-block-head-content">
                    <Breadcrumbs aria-label="breadcrumb">
                      <Link
                        color="inherit"
                        onClick={() =>
                          this.addMenuHistory(
                            "/" + auth.getResellerRegToken() + "/client/"
                          )
                        }
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        <HomeIcon fontSize="inherit" className="" />
                        Home
                      </Link>
                      <Link
                        color="inherit"
                        onClick={() =>
                          this.addMenuHistory(
                            "/" + auth.getResellerRegToken() + "/client/forms"
                          )
                        }
                        className=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                      >
                        Forms
                      </Link>

                      <Typography color="textPrimary" className="">
                        Submit Form
                      </Typography>
                    </Breadcrumbs>
                  </div>
                  <div className="nk-block-head-content" id="HeadContent">
                    <div className="toggle-wrap nk-block-tools-toggle">
                      <a
                        href="#"
                        className="btn btn-icon btn-trigger toggle-expand mr-n1"
                        data-target="pageMenu"
                      >
                        <em className="icon ni ni-more-v"></em>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-gs">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      <div className="card card-bordered">
                        <div className="card-inner">
                          {this.state.errorMessage !== "" ? (
                            <div
                              className="example-alert example-alert-revoke mt-3 mb-3"
                              style={{ marginBottom: "15px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>Error</h4>
                                  <p>{this.state.errorMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.tableLoader === true ? (
                            TableLoader()
                          ) : (
                            <>
                              {this.state.form_submitted === "No" ? (
                                <FormRendererClient
                                  formData={JSON.parse(this.state.formData)}
                                  formTitle={this.state.form_title}
                                  formDescription={this.state.form_desc}
                                  onSubmit={this.handleSubmitForm}
                                  groups={this.state.groups}
                                  responseMessage={this.state.responseMessage}
                                  clientData={this.state.client_details}
                                  allow_submit={this.state.allow_submit}
                                  // id={this.state.forms[0].id}
                                  // setShowResponse={(value) => this.setState({ showResponse: value })}
                                />
                              ) : this.state.form_submitted === "Yes" ? (
                                <>
                                  {this.state.allow_multiple === 1 ? (
                                    <FormRendererClient
                                      formData={JSON.parse(this.state.formData)}
                                      formTitle={this.state.form_title}
                                      formDescription={this.state.form_desc}
                                      onSubmit={this.handleSubmitForm}
                                      groups={this.state.groups}
                                      responseMessage={
                                        this.state.responseMessage
                                      }
                                      clientData={this.state.client_details}
                                      allow_submit={this.state.allow_submit}
                                      // id={this.state.forms[0].id}
                                      // setShowResponse={(value) => this.setState({ showResponse: value })}
                                    />
                                  ) : this.state.allow_multiple === 0 ? (
                                    <div className="nk-block mt-4">
                                      <div className="nk-block-content nk-error-ld">
                                        <h3 className="nk-error-title text-center">
                                          Form Submitted
                                        </h3>
                                        <div className="alert alert-light text-dark">
                                          Thank you for submitting your form. We
                                          have successfully received your
                                          information and are processing it. Our
                                          team will review the details and get
                                          back to you shortly if further
                                          information is needed. We appreciate
                                          your time and effort in providing us
                                          with the necessary details.
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                </>
                              ) : this.state.form_submitted === "3" ? (
                                <div className="nk-block mt-4">
                                  <div className="nk-block-content nk-error-ld  text-center">
                                    <h3 className="nk-error-title">
                                      Form Unavailable
                                    </h3>
                                    <div className="alert alert-light text-dark">
                                      We are very sorry for inconvenience. It
                                      looks like you’re try to access a form
                                      that either has been expired or already
                                      submitted.
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(FormSubmit);
