const initialState = {
  settings: "",
  menuDetails: [],
  leftMenuItems: [],
  leftClientMenuItems: [],
  is_msa_signed: "No",
  quotationSettings: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SETTINGS":
      return { ...state, settings: action.payload };
    case "SETMENU":
      return { ...state, menuDetails: action.payload };
    case "SETMSAINFO":
      return { ...state, is_msa_signed: action.payload };
    case "SET_LEFT_MENU":
      return { ...state, leftMenuItems: action.payload };
    case "SET_CLIENT_LEFT_MENU":
      return { ...state, leftClientMenuItems: action.payload };
    case "SET_QUOTATION_SETTNGS":
      return { ...state, quotationSettings: action.payload };

    default:
      return state;
  }
};
export default reducer;
